/**
 * Layout
 * ------------------------------------------------------------------------ */

/**
 * State
 * ------------------------------------------------------------------------ */

.btn-hamburger.state-is-scrolling .icon-hamburger path {
	fill: #000;
}
