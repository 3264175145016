/**
 * Layout
 * ------------------------------------------------------------------------ */

html body[path*='case-study'] {
	overflow: hidden;
}

iframe.case-study {
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 99999;
}
