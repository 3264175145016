/**
 * Layout
 * ------------------------------------------------------------------------ */

.logo.logo-buck {
	color: currentColor;
}

.logo.logo-buck path {
	stroke: currentColor;
}
